
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import "../node_modules/font-awesome/css/font-awesome.css";

/* @import "../node_modules/bootstrap/dist/css/bootstrap.min.css"; */
/* 
 @import "assets/css/style.css"; 

/* @import url("assets/css/style.css"); */
*{
    font-family: "Montserrat", sans-serif;
}
